import * as React from 'react'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const VandalismPage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "vandalism damage repair services",
	  "alternateName": "vandalism restoration services",
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${data.geoLon}
                "longitude": ${data.geoLat}
            },
            "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "vandalism damage repair services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "vandalism damage repair services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "vandalism damage repair services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "vandalism damage restoration services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="Vandalism Repair Services"
        description="Ecotech’s Restoration offers complete vandalism cleanup and repair services for your home and property."
        canonical="https://ecotechrestoration.ca/services/vandalism-repair/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />
      {/* Heros */}
      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/vandalism.jpg"
          quality={95}
          alt="Vandalism Repair Services"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          Vandalism Clean-Up Services
        </h1>
      </div>

      <SiteWidthWrapper>
        <CallOut>Do you have a Vandalism Related Emergency?</CallOut>
        <p>
          Vandalism is a very frustrating crime to have happened to your
          property. It can leave some people feeling personally attacked, others
          disgusted, and for many others, it’s an ongoing nuisance. We
          understand this, which is why we take these kinds of jobs very
          seriously.
        </p>
        <p>
          Ecotech’s Restoration offers complete vandalism cleanup and repair
          services.
        </p>
        <p>
          We will cleanup and repair all of the damage caused to your property
          and restore it back to its normal condition.
        </p>
        <p>
          Our technicians utilize the best cleaning products in the industry and
          are able to remove nearly any graffiti or other substances commonly
          used by vandals. This includes damage to your furniture, walls,
          flooring, and signage.
        </p>
        <h2>We clean up hazardous materials.</h2>
        <p>
          We offer biohazard clean up services for properties damaged by
          potentially hazardous materials, such as fecal matter or blood. We
          will sanitize, disinfect and deodorize the entire area and remove all
          of the potential health risks.
        </p>
        <h2>We work with insurance companies to remove vandalism damage</h2>
        <p>
          We will work with your insurance company if the damage done to your
          property falls within an insurance claim.
        </p>
        <h2>
          We’re available 24/7 and will deal with any type of damage including:{' '}
        </h2>
        <ul>
          <li>graffiti removal</li>
          <li>fixing broken windows</li>
          <li>fixing broken doors</li>
          <li>egg residue</li>
          <li>fecal matter</li>
          <li>food waste</li>
          <li>smoke damage repair</li>
          <li>water damage repair</li>
          <li>general cleanup</li>
          <li>power washing</li>
          <li>disinfecting</li>
          <li>odor removal</li>
        </ul>
        <SplashTwo title="Vandalism Repairs" />
      </SiteWidthWrapper>
    </Layout>
  )
}

export default VandalismPage
